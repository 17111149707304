@import url('../../styles/customMediaQueries.css');

/* 404 page */

.root {
  display: flex;
  flex-direction: column;

  /* Expand to the available space */
  flex-grow: 1;
  align-items: center;
}

.content {
  width: 80%;
  max-width: 587px;
  margin-top: 60px;
  margin-bottom: 60px;

  @media (width >= 768px) {
    margin-top: 10vh;
  }
}

.number {
  composes: marketplaceHeroTitleFontStyles from global;
  margin: 1px 0 0;
  color: var(--marketplaceColor);
  text-align: center;

  @media (width >= 768px) {
    margin: 0;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
  margin-top: var(--n-size-2);
  text-align: center;

  @media (width >= 768px) {
    margin-top: 22px;
  }
}

.description {
  margin-bottom: 0;
  text-align: center;

  @media (width >= 768px) {
    margin-top: var(--n-size-3);
    margin-bottom: 0;
  }
}

.searchForm {
  max-width: 408px;
  margin: 62px auto 0;

  background-color: var(--colorWhite);
  border-bottom: 4px solid var(--marketplaceColor);
  box-shadow: var(--boxShadowNotFoundPageSearch);

  @media (width >= 768px) {
    margin-top: var(--n-size-8);
  }
}
