.root {
  cursor: pointer;

  width: 100%;
  height: 100%;

  background-color: transparent;
  border: 0;

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
}

.clickedWithMouse {
  &:focus {
    outline: none;
  }
}

/* Default isOpen class - no styles yet */
.isOpen {
}
