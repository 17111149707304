@import url('../../../styles/customMediaQueries.css');

.impact {
  display: flex;
  flex-wrap: wrap;
  gap: var(--n-size-1);
  align-items: center;

  width: 100%;
  padding-top: var(--n-size-7);
  padding-bottom: var(--n-size-4);

  font-family: Montserrat;
  font-size: var(--n-font-size-00);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  white-space: pre-wrap;

  & > * {
    flex: 1;
  }
}

.impact__title {
  margin: 0;
  padding: 0;

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-3);
  font-weight: 700;
  line-height: 1.5;
  color: var(--n-color-text-dark);
  text-transform: uppercase;
}

.impact__stat {
  display: grid;
  gap: var(--n-size-1);
  justify-items: center;
  text-align: center;

  & img {
    width: 40px;
    height: 40px;
  }

  @media (width >= 768px) {
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: start;
    text-align: left;
  }
}

.impact__statText {
}

.impact__statMain {
  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-0);
  font-weight: 700;
}

.impact__statSecondary {
  font-family: Montserrat;
  font-size: var(--n-font-size-000);
  font-weight: 400;
  line-height: 15px;
}

.impact__button {
  display: flex;
  flex: 2;
  justify-content: flex-end;
}
