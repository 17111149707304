@import url('../../styles/customMediaQueries.css');

.tabContent {
  padding: var(--n-size-3) var(--n-size-3) var(--n-size-5) var(--n-size-3);

  h1,
  h2 {
    font-family: Syne, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }

  h1 {
    font-size: var(--n-font-size-3);
  }

  h2 {
    font-size: var(--n-font-size-2);
  }

  img {
    aspect-ratio: 2.5;
    width: 100%;
    border-radius: var(--radius-md);
  }
}

.genericContent {
  display: grid;
  gap: var(--n-size-4);
}
