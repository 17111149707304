.root {
  display: grid;
  gap: var(--n-size-3);
}

.item {
  display: grid;
  gap: var(--n-size-2);

  padding: calc(var(--n-size-3) + 1px);

  border: 1px solid var(--n-color-border);
  border-radius: var(--radius-md);

  transition: background-color 0.2s ease;

  &[data-selected='true'] {
    padding: var(--n-size-3);
    border-color: var(--n-color-black);
    border-width: 2px;
  }
}

.cardRow {
  display: flex;
  gap: var(--n-size-2);
  align-items: center;
}

.card {
  overflow: hidden;
  display: grid;

  aspect-ratio: 1.586;
  height: 80px;

  color: white;

  background: var(--n-color-white);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid var(--n-color-black);
  border-radius: var(--radius-md);
}

.mainInfo {
  display: grid;
  gap: var(--n-size-1);
  align-content: center;

  & h1 {
    font-family: Syne, sans-serif;
    font-size: var(--n-font-size-3);
    font-weight: 700;
    text-transform: uppercase;
  }

  & h2 {
    font-family: Montserrat, sans-serif;
    font-size: var(--n-font-size-0);
    font-weight: 400;
  }
}

.useInfo {
  display: flex;
  gap: var(--n-size-2);
  justify-content: space-between;

  font-size: var(--n-font-size-0);
  font-weight: 400;

  & a {
    text-decoration: underline;
  }
}
