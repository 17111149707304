@import url('../../styles/customMediaQueries.css');

.h1 {
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 3px 0;

  font-size: var(--n-font-size-3);
  font-weight: var(--fontWeightBlack);
  line-height: 24px;

  @media (width >= 768px) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: var(--n-size-3);
    margin-bottom: var(--n-size-3);
    padding: 0;

    font-size: 64px;
    line-height: 64px;
  }
}

.h2 {
  /* x * 6px */
  margin-top: var(--n-size-3);
  margin-bottom: 18px;
  padding: 4px 0 2px;

  font-size: 21px;
  font-weight: var(--fontWeightBold);
  line-height: 24px;

  @media (width >= 768px) {
    /* x * 8px */
    margin-top: var(--n-size-3);
    margin-bottom: var(--n-size-2);
    padding: 5px 0 3px;

    font-size: 40px;
    line-height: 56px;
  }
}

.h3 {
  /* x * 6px */
  margin-top: 18px;
  margin-bottom: 12px;

  font-size: var(--n-font-size-1);
  font-weight: var(--fontWeightBold);
  line-height: 24px;

  @media (width >= 768px) {
    /* x * 8px */
    margin-top: var(--n-size-2);
    margin-bottom: var(--n-size-2);
    padding: 1px 0 7px;

    font-size: var(--n-font-size-4);
    line-height: 40px;
  }
}

.h4 {
  /* x * 6px */
  margin-top: 12px;
  margin-bottom: 6px;
  padding: 1px 0 5px;

  font-size: 21px;
  font-weight: var(--fontWeightBold);
  line-height: 30px;

  @media (width >= 768px) {
    /* x * 8px */
    margin-top: var(--n-size-2);
    margin-bottom: var(--n-size-2);
    padding: 0;
    line-height: 32px;
  }
}

.h5 {
  margin-top: 12px;
  margin-bottom: 6px;

  /* x * 6px */
  padding: 3px 0;

  font-size: var(--n-font-size-00);
  font-weight: var(--fontWeightMedium);
  line-height: 18px;

  @media (width >= 768px) {
    margin-top: var(--n-size-1);
    margin-bottom: var(--n-size-2);

    /* x * 8px */
    padding: 3px 0 5px;
    line-height: 16px;
  }
}

.h6 {
  margin-top: 6px;
  margin-bottom: 6px;

  /* x * 6px */
  padding: 4px 0 2px;

  font-size: var(--n-font-size-000);
  font-weight: var(--fontWeightBold);
  line-height: 18px;
  text-transform: uppercase;

  @media (width >= 768px) {
    margin-top: var(--n-size-1);
    margin-bottom: var(--n-size-1);

    /* x * 8px */
    padding: 3px 0 5px;
    line-height: 16px;
  }
}
