@import url('../../../styles/customMediaQueries.css');

.root {
  background-color: rgb(255 255 255 / 90%);
  backdrop-filter: blur(8px);
}

.maxWidthWrapper {
  display: flex;
  flex-direction: row;
  gap: var(--n-size-4);
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: var(--n-content-max-width);
  height: var(--topbarHeight);
  margin: 0 auto;
  padding: 0 var(--n-size-3);

  @media (width >= 1024px) {
    padding: 0 var(--n-size-10);
  }
}

.logoLink {
  transform: scale(1.5);

  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom: 0 solid;
}

.searchLink {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  height: 100%;
  margin-right: var(--n-size-3);

  @media (width >= 1024px) {
    padding-left: 0;
  }
}

.search {
  display: inline-block;

  margin: 28px 0;

  font-size: 15px;
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
  color: var(--colorGrey700);
  text-decoration: inherit;
  letter-spacing: 0;

  @media (width >= 768px) {
    font-weight: var(--fontWeightMedium);
  }
}

.profileMenuLabel {
  display: flex;
  flex-shrink: 0;
  align-items: center;

  height: 100%;
  margin: 0;
  padding: 0 0 0 12px;

  border-bottom: 0 solid;

  transition: var(--transitionStyleButton);
}

.avatar {
  margin: var(--n-size-2) 0 var(--n-size-2) var(--n-size-1);
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.menu {
  height: 50%;
}

.signupLink {
  flex-shrink: 0;

  height: 100%;
  padding: 0 12px;

  color: var(--colorGrey700);

  border-bottom: 0 solid;

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
  }
}

.loginLink {
  flex-shrink: 0;

  height: 100%;
  padding: 0 12px;

  color: var(--colorGrey700);

  border-bottom: 0 solid;

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
  }
}

.signup,
.login {
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0;

  width: 0;
  height: calc(100% - 4px);

  transition: width var(--transitionStyleButton);
}

.li {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;

  height: 40px;
  padding: 0 var(--n-size-1);

  &:hover {
    background: var(--background-light-seconday, #f5f7fa);
    border-radius: 0;
  }
}

.profileSettingsLink,
.yourListingsLink {
  position: relative;

  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;

  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
  }
}

.logoutButton {
  /* Dimensions */
  position: relative;

  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px var(--n-size-3);

  font-size: var(--n-font-size-00);

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  line-height: 18px;

  /* Layout details */
  color: var(--colorGrey300);
  text-align: left;

  /* Text size should be inherited */
  text-decoration: none;
  letter-spacing: 0;
  white-space: nowrap;

  transition: var(--transitionStyleButton);

  @media (width >= 768px) {
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 16px;
  }
}

.currentPage {
  position: relative;
  color: var(--colorBlack);
}

.sectionBase {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.sectionLeft {
  composes: sectionBase;
  gap: var(--n-size-2);
  justify-content: flex-start;
}

.sectionCenter {
  composes: sectionBase;
  flex: unset;
}

.sectionRight {
  composes: sectionBase;
  gap: 4px;
  justify-content: flex-end;
}

.basicLink {
  display: grid;
  place-content: center;

  height: 100%;
  padding: 0 var(--n-size-1);

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-0);
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  text-decoration: none !important;
  text-transform: uppercase;
  letter-spacing: 0;

  border-bottom: 2px solid transparent;

  transition: border 0.15s;
}

.basicLink:hover {
  border-bottom-color: var(--n-color-text-dark);
}

.linkIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--n-size-1);

  color: #1c1b1f;

  background: transparent;
  border-radius: 50%;

  transition: background-color 0.15s;
}

.linkIconWrapper:hover {
  background: var(--background-light-seconday, #f5f7fa);
}

.logout {
  font-family: Montserrat;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);

  &:hover {
    text-decoration: none;
  }
}

.profileMenu__content {
  display: grid;
  gap: var(--n-size-1);

  padding: var(--n-size-2);

  background-color: var(--n-color-white);
  border-radius: var(--radius-md);
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%);

  & > * {
    cursor: pointer;

    padding: var(--n-size-1) var(--n-size-2);

    font-family: var(--fontFamilySyne);
    font-size: var(--n-font-size-0);
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;

    border: none;

    /* TODO: Fix tab index */
    outline: none;

    &:hover {
      background-color: var(--background-light-seconday, #f5f7fa);
      border-radius: var(--radius-md);
    }
  }
}
