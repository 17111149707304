@import url('../../../styles/customMediaQueries.css');

.favButton {
  cursor: pointer;

  position: absolute;
  top: var(--n-size-2);
  right: var(--n-size-2);

  padding: var(--n-size-1);

  color: var(--n-color-text-dark);

  & > svg {
    transition: transform 0.1s;

    &:hover {
      transform: scale(1.1);
    }
  }
}
