@import url('../../styles/customMediaQueries.css');

.root {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
}

.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noImageIcon {
  box-sizing: content-box;
  padding-top: var(--n-size-3);
  stroke: var(--colorGrey700);
}

.noImageText {
  composes: h6 from global;
  margin-bottom: 0;
  padding-bottom: var(--n-size-3);
  color: var(--colorGrey700);

  @media (width >= 768px) {
    margin-bottom: 0;
  }
}
