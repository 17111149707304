@import url('../../styles/customMediaQueries.css');

.root {
  position: sticky;
  bottom: 0;

  display: grid;

  height: var(--mobileBottomNavHeight);

  background-color: rgb(255 255 255 / 90%);
  backdrop-filter: blur(8px);

  @media (width >= 768px) {
    display: none;
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}

.menuItem {
  cursor: pointer;

  display: grid;
  gap: 4px;
  place-content: center stretch;
  place-items: center;

  /* Caption */
  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-000);
  font-weight: 700;
  color: var(--n-color-text-subtle);
  text-transform: uppercase;

  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;

  &,
  &:hover {
    text-decoration: none;
  }

  &[data-active='true'] {
    border-bottom-color: var(--n-color-black);
  }
}

.menuItemCenter {
  cursor: pointer;
  display: grid;
  place-content: center;
  color: white;

  & > * {
    display: grid;
    place-content: center;

    width: 48px;
    height: 48px;

    background: var(--icon-default-default, var(--n-color-black));
    border-radius: 50%;
  }
}
