.root {
  padding: 10px 20px 9px;
  text-align: center;
  background-color: #df492a;
}

.text {
  display: inline-block;

  margin: 0;
  margin-bottom: var(--n-size-2);

  font-size: var(--n-font-size-0);
  line-height: 20px;
  color: var(--n-color-white);
}

.button {
  margin: 0 var(--n-size-2);
  padding: var(--n-size-1) var(--n-size-2);

  font-size: var(--n-font-size-00);
  color: var(--n-color-white);

  background: #2a3d4b;
  border: 0;
  border-radius: var(--borderRadiusMedium);

  &:hover {
    text-decoration: none;
    background: #364f61;
  }
}
