@import url('../../styles/customMediaQueries.css');

.root {
  display: none;

  @media (width >= 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 50%;

    background-color: white;
  }

  @media (width >= 1024px) {
    padding: 0 36px 0 0;
  }
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background-color: #f5f7fa;
  }
}

.tab a {
  @media (width < 768px) {
    padding: var(--n-size-1) 0;

    font-family: Montserrat;
    font-size: var(--n-font-size-0);
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    color: var(--n-color-text-dark);
    text-transform: uppercase;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--n-color-border-active);

  @media (width < 768px) {
    max-width: 90vw;
    border-right: none;
  }
}

.wrapperLabel {
  display: none;

  @media (width >= 769px) {
    display: block;

    /* Heading/H4 */
    font-family: var(--fontFamilySyne);
    font-size: var(--n-font-size-3);
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    color: var(--n-color-black);
  }
}

.horizontalNav {
  display: none;

  @media (width < 768px) {
    overflow-x: auto;
    display: flex;
    flex-wrap: inherit;
    gap: 30px;
    align-items: center;
    align-self: stretch;

    width: 100%;

    text-align: center;
  }
}

.horizontalNav::-webkit-scrollbar {
  display: none;
}

.verticalNav {
  display: none;

  @media (width >= 769px) {
    display: block;
  }
}
