@import url('../../../styles/customMediaQueries.css');

.root {
  display: grid;
  margin: 0;
}

.profileMyOrdersWrapper {
  display: flex;
  gap: var(--n-size-3);
  align-items: center;
  align-self: stretch;

  padding: var(--n-size-2) 0;
}

.myOrdersTitle {
  display: block;

  margin: 0;
  padding: 0;

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-3);
  font-weight: 700;
  color: var(--n-color-text-dark);
  text-transform: uppercase;
}

.order {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--n-size-2);
  align-items: center;

  padding: var(--n-size-3) 0;

  border-bottom: 1px solid var(--n-color-border);

  &:last-child {
    border-bottom: none;
  }

  & > *:first-child {
    grid-row: 1 / 3;
  }

  @media (width >= 768px) {
    grid-template-columns: auto 1fr 1fr;

    & > *:first-child {
      grid-row: initial;
    }
  }
}

.title {
  margin: 0;
  padding: 0;

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-000);
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

.detailsAspectWrapper {
  overflow: hidden;

  aspect-ratio: 3 / 4;
  width: 84px;

  object-fit: cover;
  border-radius: var(--radius-md);
}

.imageWrapper {
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
  border-radius: var(--radius-md);
}

.price {
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
}

.sectionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: var(--n-size-3) 0;

  @media (width >= 768px) {
    flex-direction: row;
  }
}

.mainInfo {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: var(--n-size-2);
  align-items: flex-start;
  align-self: stretch;
  justify-content: center;
}

.trackOrderBtn {
  display: flex;
  gap: var(--n-size-1);
  align-items: center;
  justify-content: center;

  height: 32px;
  padding: var(--n-size-1) var(--n-size-3);

  background: var(--n-color-white);
  border: 1px solid var(--n-color-text-subtle);
  border-radius: var(--radius-full);

  &:hover {
    cursor: pointer;
    text-decoration: none;
    background: var(--background-light-seconday, #f5f7fa);
  }
}

.trackOrderBtn span {
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-00);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
}

.reviewItem {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: flex-start;
  justify-content: end;

  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-00);
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);

  & span div {
    gap: 0;
  }

  @media (width >= 368) {
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }
}

.orderInfo {
  display: grid;
  gap: var(--n-size-2);
  justify-items: start;
  text-align: left;

  @media (width >= 768px) {
    justify-items: end;
    text-align: right;
  }
}

.wrapper {
  display: flex;
  gap: var(--n-size-1);
  align-items: center;
  justify-content: flex-end;
}

.date {
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-00);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
}

.pending,
.accepted,
.declined {
  font-family: Montserrat;
  font-size: var(--n-font-size-0);
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-subtle);
}

.orderID {
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-00);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
}
