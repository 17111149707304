@import url('../../styles/customMediaQueries.css');

.error {
  margin-top: 5px;
  color: var(--colorFail);

  @media (width >= 768px) {
    margin-top: 4px;
  }

  @media (width >= 1024px) {
    margin-top: 3px;
  }
}

.mobileReviewsTitle {
  margin-right: var(--n-size-2);
  margin-bottom: var(--n-size-2);

  /* Heading/H3 */
  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-3);
  font-weight: 700;
  color: var(--n-color-text-dark);
  text-transform: uppercase;
}

.mobileReviews {
  display: grid;
  width: 100%;
  max-width: 1224px;
  margin: var(--n-size-4) auto;
}

.mobileReviewsPagination {
  margin-top: var(--n-size-4);
}

.profileContainer {
  display: grid;
  gap: var(--n-size-2);
  align-content: start;
  padding: var(--n-size-5) 0;
}

.maxWidthContainer {
  width: 100%;
  max-width: var(--n-content-max-width);
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--n-size-2);
  padding-left: var(--n-size-2);

  @media (width >= 1024px) {
    padding-right: var(--n-size-10);
    padding-left: var(--n-size-10);
  }
}

.verticalDivider {
  display: inline-block;
  width: 1px;
  height: 24px;
  background: var(--n-color-border-active);

  @media (width <= 385px) {
    display: none;
  }
}

.infoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (width <= 767px) {
    .addListingBtn,
    .profileSettingsBtn {
      display: none;
    }
  }
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    border-bottom: 1px solid var(--n-color-border);
  }
}

.tab button {
  font-family: Montserrat;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
  text-transform: uppercase;
}

.horizontalNav {
  overflow-x: auto;
  display: flex;
  flex-wrap: inherit;
  gap: 15px;
  align-items: center;
  align-self: stretch;

  width: 100%;
  margin-top: 20px;

  text-align: center;
}

.horizontalNav::-webkit-scrollbar {
  display: none;
}

.horizontalNav button {
  border: none;
}

.wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profileReviewsWrapper {
  display: flex;
  align-items: center;

  @media (width < 768px) {
    display: block;
  }
}

.profileSettingsMobileBtn {
  display: flex;
  gap: var(--n-size-1);
  align-items: center;
  justify-content: center;

  height: 32px;
  padding: var(--n-size-1) var(--n-size-3);

  background: var(--n-color-white);
  border: 1px solid var(--n-color-text-subtle);
  border-radius: var(--radius-full);

  &:hover {
    cursor: pointer;
    text-decoration: none;
    background: var(--background-light-seconday, #f5f7fa);
  }

  @media (width >= 768px) {
    display: none;
  }
}

.profileSettingsMobileBtn span {
  /* Body/Body 1/Regural */
  font-family: Montserrat;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
}

.navWrapper {
  max-width: 90vw;
}

.ratingWrapper {
  display: flex;
  gap: var(--n-size-1);
  align-items: center;
  margin: var(--n-size-2) 0 var(--n-size-4);

  @media (width <= 385px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.emptyStateWithButton {
  display: grid;
  gap: var(--n-size-5);
  justify-items: center;

  padding: var(--n-size-10) var(--n-size-2);

  font-family: Montserrat;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  text-align: center;
}
