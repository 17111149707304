.root {
  position: relative;

  overflow: hidden;
  display: block;

  width: 100%;
  margin: 0;

  background-color: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--radius-md);
}

.aspectWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
  border-radius: var(--borderRadius);
}
