@import url('../../styles/customMediaQueries.css');

.root {
  display: grid;
  gap: var(--n-size-5);
  margin: 0;
}

.reviewItem {
  padding-bottom: var(--n-size-3);
  border-bottom: 1px solid var(--n-color-border);
}

.review {
  display: grid;
  grid-template-columns: auto 1fr;
}

.avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: var(--n-size-1);

  @media (width >= 768px) {
    width: 80px;
    height: 80px;
    margin-right: var(--n-size-3);
  }
}

.headerContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--n-size-1);
  justify-content: space-between;
}

.reviewContent {
  margin: 12px 0 0;

  /* Body/Large/Regular */
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);

  @media (width >= 768px) {
    font-size: var(--n-font-size-1);
  }
}

.authorName {
  margin: 0;
  padding: 0;

  /* Body/Large/Bold */
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);

  @media (width >= 768px) {
    font-size: var(--n-font-size-1);
  }
}

.date {
  /* Body/Body 1/Semi-bold */
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-00);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-subtle);
  text-align: right;

  @media (width >= 768px) {
    font-size: var(--n-font-size-0);
    font-weight: 600;
  }
}

.reviewInfo {
  composes: h5 from global;
  margin-top: 10px;
  margin-bottom: 0;
  color: var(--colorGrey300);

  @media (width >= 768px) {
    margin-top: 9px;
  }
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  display: none;
  margin: 0 7px;

  @media (width >= 768px) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (width >= 768px) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: none;

  @media (width >= 768px) {
    display: inline-flex;
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  white-space: nowrap;
}

.reviewRatingStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}
