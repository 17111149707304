@import url('../../styles/customMediaQueries.css');

.root {
  isolation: isolate;

  /* Top Bar's drop shadow should always be visible */
  position: sticky;
  width: 100%;
}

.mobileContainer {
  /* Layout for child components */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  /* Size */
  width: 100%;
  height: var(--topbarHeight);
  padding: 0 var(--n-size-1);

  /* fill */
  background-color: var(--colorWhite);
  background-color: rgb(255 255 255 / 90%);
  backdrop-filter: blur(8px);

  @media (width >= 768px) {
    display: none;
  }
}

.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.searchMenu {
  /* Font */
  composes: h5 from global;

  /* Layout */
  display: flex;

  margin: 0;
  padding: var(--n-size-2);

  color: var(--colorGrey700);

  background-color: transparent;
  border-width: 0;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }

  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }

  &:disabled {
    cursor: auto;
    background-color: transparent;
  }
}

.desktop {
  display: none;

  @media (width >= 768px) {
    display: block;
  }
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
}

.mobileHelp {
  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
  margin: 0 var(--n-size-3);
  color: var(--colorGrey300);
}

/* ================ Modal ================ */

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;

  @media (width >= 768px) {
    flex-basis: 576px;
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.searchPopover {
  width: 100%;
  min-width: var(--radix-popover-content-available-width);

  & form {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: var(--n-size-1);
    align-items: center;
    justify-content: stretch;

    height: var(--topbarHeight);
    padding: 0 var(--n-size-2);

    background: var(--n-color-white);
    border-bottom: 1px solid var(--n-color-border);

    & > button {
      padding: 0;
      border: 0;
    }

    @media (width >= 768px) {
      padding: 0 var(--n-size-3);
    }
  }

  & input {
    height: 100%;
    border: none;
  }
}

.userResults {
  overflow-y: auto;
  display: grid;
  gap: var(--n-size-2);

  width: var(--radix-popover-content-available-width);
  max-height: min(60vh, 800px);
  padding: var(--n-size-4);

  background: var(--n-color-white);
  border-bottom: 1px solid var(--n-color-border);
}

.userResult {
  display: flex;
  flex-wrap: wrap;
  gap: var(--n-size-2);
  align-items: center;

  & img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 50%;
  }

  &:hover {
    text-decoration: none;
  }
}

.userResult__info {
  flex-grow: 1;
  word-break: break-word;
}

.overlay {
  height: 100vh;
  background-color: rgb(0 0 0 / 50%);
  backdrop-filter: blur(8px);
}

.promotionBanner {
  display: flex;
  align-items: center;

  width: 100%;
  height: var(--topbarBannerHeight);

  text-align: center;

  background-color: var(--n-color-black);
}

.promotionBanner span {
  align-items: center;

  margin: auto;

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-0000);
  font-weight: 700;
  color: white;
  text-transform: uppercase;
}

.promotionBanner span a {
  color: white;
  text-decoration: underline;
}

@media (width >= 768px) {
  .promotionBanner {
    width: 100%;
  }

  .promotionBanner div {
    width: 100%;
    height: 100%;
  }

  .promotionBanner span {
    width: 100%;
    font-size: var(--n-font-size-000);
  }
}
