@import url('../../../styles/customMediaQueries.css');

.mobileWallets {
  overflow-x: hidden;
  display: grid;
  gap: var(--n-size-2);

  width: 100%;
  padding-top: var(--n-size-4);
  padding-bottom: var(--n-size-4);
}

.header {
  overflow-x: auto;
  display: flex;
  gap: var(--n-size-4);
  align-items: center;
  justify-content: center;

  padding: var(--n-size-3) 0;

  & > * {
    flex-shrink: 0;
  }

  @media (width >= 768px) {
    gap: var(--n-size-6);
  }
}

.earned {
  display: grid;

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-3);
  font-weight: 700;
  line-height: 1.5;
  color: var(--n-color-text-dark);
  text-align: center;
}

.earned__text {
  font-size: 0.5em;
  text-transform: uppercase;
}

.root {
  display: grid;
  margin: 0;
}

.walletItem {
  padding: 0;
  border-bottom: 1px solid var(--n-color-border);

  &:last-child {
    border-bottom: none;
  }
}

.sale {
  display: grid;
  grid-template-columns: auto 1fr;
}

.brandName {
  font-family: var(--fontFamilySyne);
  font-size: 8px;
  font-weight: 700;
  color: var(--n-color-text-subtle);
  text-transform: uppercase;
}

.title {
  margin: 0;
  padding: 0;

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-000);
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

.imageContainer {
  overflow: hidden;

  aspect-ratio: 3 / 4;
  width: 84px;

  object-fit: cover;
  border-radius: var(--radius-md);
}

.imageWrapper {
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
}

.resalePrice {
  display: flex;
  flex-direction: column;

  /* Body/Body 2/Regular */
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-00);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-subtle);
}

.price {
  margin-top: var(--n-size-1);

  /* Body/Body 1/Semi-bold */
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
}

.sectionWrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--n-size-2);
  padding: var(--n-size-3) 0;

  & > *:first-child {
    grid-row: 1 / 4;
  }

  & p {
    margin: 0;
  }

  @media (width >= 768px) {
    grid-template-columns: auto 3fr 2fr 2fr;
    grid-template-rows: auto;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;

    & > *:first-child {
      grid-row: initial;
    }
  }
}

.statusWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--n-size-1);
  align-items: flex-start;

  min-width: 280px;
}

.status {
  /* Body/Body 1/Semi-bold */
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
}

.statusDescription {
  /* Body/Body 2/Regular */
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-00);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-subtle);
}

.priceInfo {
  display: grid;
  gap: var(--n-size-2);
}

.reviewBuyer {
  display: inline-flex;
  gap: 12px;
  align-items: center;

  min-width: max-content;

  /* Body/Body 1/Semi-bold */
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
}

.orderInfo {
  display: grid;
  gap: var(--n-size-2);

  @media (width >= 768px) {
    justify-items: end;
  }
}

.copyInfo {
  display: inline-flex;
  gap: var(--n-size-1);
  align-items: center;
}

.date {
  margin-right: var(--n-size-1);

  /* Body/Body 2/Regular */
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-00);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
}

.code {
  /* Body/Body 1/Regural */
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
}

.emptyStateWithButton {
  display: grid;
  gap: var(--n-size-5);
  justify-items: center;

  padding: var(--n-size-10) var(--n-size-2);

  font-family: Montserrat;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  text-align: center;
}
