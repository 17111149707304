@import url('../../styles/customMediaQueries.css');

.buttonDefault {
  cursor: pointer;

  position: relative;

  /* TODO: Default should be inline-flex. Then you should be able to override to flex. */
  display: inline-flex;
  gap: 12px;
  align-items: center;
  justify-content: center;

  min-height: 40px;
  padding: var(--n-size-1) var(--n-size-3);

  /* Body/Body 1/Semi-bold */
  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-0);
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 0.07em;

  background: var(--n-color-white);
  border: 1px solid var(--n-color-black);
  border-radius: var(--radius-full);
}

.root {
  composes: buttonDefault;
}

.primaryButtonRoot {
  composes: buttonDefault;
  color: var(--n-color-white);
  background-color: var(--n-color-black);

  &:disabled {
    background-color: #d2d2d2;
    border-color: #d2d2d2;
  }
}

.primaryButtonInlineRoot {
  composes: buttonPrimaryInline from global;
}

.secondaryButtonRoot {
  composes: buttonSecondary from global;

  /* We must lift up the text from the center since it looks better with
   the button border. Also clears the top padding set for link
   elements looking like buttons */
  padding: 0 0 2px;
}

.secondaryButtonInlineRoot {
  composes: buttonSecondaryInline from global;
  margin: 17px 0 0;
}

.secondaryButton {
  /* Class handle for secondary button state styles */
}

.secondaryButtonInline {
  /* Class handle for secondary button state styles */
}

.inProgress {
  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.secondaryButton.inProgress:disabled,
.secondaryButtonInline.inProgress:disabled {
  background-color: var(--colorWhite);
}

.ready {
  padding: 0;

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.secondaryButton.ready:disabled,
.secondaryButtonInline.ready:disabled {
  background-color: var(--colorWhite);
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 28px;
  height: 28px;

  stroke: var(--colorWhite);
  stroke-width: 3px;
}

.checkmark {
  width: 24px;
  height: 24px;

  stroke: var(--colorWhite);

  /* Animation */
  animation-name: checkmarkAnimation;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
}

@keyframes checkmarkAnimation {
  0% {
    transform: scale(0.75);
    opacity: 0;
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.secondaryButton .spinner,
.secondaryButtonInline .spinner {
  stroke: var(--colorGrey300);
}

.secondaryButton .checkmark,
.secondaryButtonInline .checkmark {
  stroke: var(--colorGrey300);
}

/* Social logins && SSO buttons */

.socialButtonRoot {
  cursor: pointer;

  /* We need to add this to position the icon inside button */
  position: relative;

  display: block;

  width: 100%;
  min-height: 48px;
  margin: 0;
  padding: 0;

  font-family: var(--fontFamily);
  font-size: var(--n-font-size-00);
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
  color: var(--colorBlack);
  text-align: center;
  text-decoration: none;

  background-color: var(--colorWhite);

  /* border: 1px solid #d2d2d2; */

  /* border-radius: var(--borderRadiusMedium); */
  border: unset;

  transition: all var(--transitionStyleButton);

  &:hover,
  &:focus {
    text-decoration: none;
    text-transform: scale(2.1);

    /* box-shadow: var(--boxShadowButton); */
    background-color: var(--colorWhite);
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--colorWhite);
    background-color: var(--colorGrey100);
    box-shadow: none;
  }

  @media (width >= 768px) {
    padding: 0;
    font-size: var(--n-font-size-0);
    line-height: 24px;
  }
}

.socialButton {
}
