.root {
  display: grid;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid var(--n-color-border);
}

.tabsContainer {
  scrollbar-width: none;

  overflow-x: auto;
  display: flex;
  align-items: center;

  max-width: 100%;
  margin: 0 auto;

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tab {
  cursor: pointer;

  flex-shrink: 0;

  width: 140px;
  padding: var(--n-size-2) var(--n-size-1);

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-000);
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;

  border-bottom: 2px solid transparent;

  &[data-active='true'] {
    border-bottom: 2px solid var(--n-color-text-dark);
  }
}
