@import url('../assets/sanitize.css');
@import url('./customMediaQueries.css');

/* ================ Custom Properties aka CSS variables ================ */

/**
 * These variables are available in global scope through ":root"
 * element (<html> tag). You can use Custom Properties to achieve
 * more dynamic theming by changing the value on the fly with JS:
 * document.documentElement.style.setProperty("--marketplaceColor", '#55AA55');
 *
 * Read more about how to use CSS Custom Properties
 * https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
 */

:root {
  /* ================ Colors ================ */

  /**
   * Marketplace color comes by default from src/config/brandingConfig.js
   * It overwrites these CSS Properties.
   * In the future, we are planning to bring more --marketplace* CSS Properties
   * from hosted assets, which means that they could be managed through Console.
   */
  --marketplaceColor: #1d1d1d;

  /* hsl(260, 63%, 46%) */
  --marketplaceColorLight: #404040;

  /* hsl(260, 63%, 56%), +10L from marketplaceColor */
  --marketplaceColorDark: #000;

  /* hsl(260, 63%, 26%), -10L from marketplaceColor */

  /* Colors used for different functions (like error texts) */

  --colorSuccess: #2ecc71;
  --colorSuccessDark: #239954;
  --colorSuccessLight: #f0fff6;
  --colorFail: #f00;
  --colorFailLight: #fff0f0;
  --colorAttention: #fa0;
  --colorAttentionLight: #fff7f0;
  --colorBannedLight: var(--marketplaceColorLight);
  --colorBannedDark: var(--marketplaceColor);

  /* B&W and Grey palette */

  --colorWhite: hsl(0deg 0% 100%);
  --colorGrey50: hsl(240deg 3% 98%);
  --colorGrey100: hsl(240deg 3% 90%);
  --colorGrey200: hsl(240deg 3% 80%);
  --colorGrey300: hsl(240deg 3% 70%);
  --colorGrey400: hsl(240deg 3% 60%);
  --colorGrey500: hsl(240deg 3% 50%);
  --colorGrey600: hsl(240deg 3% 40%);
  --colorGrey700: hsl(240deg 3% 30%);
  --colorGrey800: hsl(240deg 3% 20%);
  --colorGrey900: hsl(240deg 3% 10%);
  --colorBlack: hsl(0deg 0% 0%);
  --colorLightTransparent: rgb(255 255 255 / 65%);

  /* ================ Font ================ */
  --fontFamily: Montserrat, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --fontFamilySyne: Syne, var(--fontFamily);
  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;
  --fontWeightHeavy: 800;
  --fontWeightBlack: 900;
  --fontWeightHighlightEmail: var(--fontWeightBold);

  /* ================ Spacing units ================ */

  /**
   * Note: changing --contentMaxWidth does not affect layouts with LayoutSideNavigation or search pages.
   */
  --contentMaxWidth: 1056px;
  --contentMaxWidthPages: 1120px;

  /* calc(Global width (1056px) + (Horizontal padding (32px) * 2) */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
  --spacingUnit: 6px;
  --spacingUnitDesktop: 8px;

  /* Shadows */
  --boxShadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  --boxShadowTop: 0 -2px 4px 0 rgb(0 0 0 / 10%);
  --boxShadowLight: 0 2px 4px 0 rgb(0 0 0 / 5%);
  --boxShadowPopup: 0 8px 16px 0 rgb(0 0 0 / 30%);
  --boxShadowPopupLight: 0 3px 6px 0 rgb(0 0 0 / 20%);
  --boxShadowBreakdown: 0 3px 6px 0 rgb(0 0 0 / 20%);
  --boxShadowButton: 0 4px 8px 0 rgb(0 0 0 / 10%);
  --boxShadowListingCard: 0 0 50px 0 rgb(0 0 0 / 10%);
  --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgb(0 0 0 / 50%);
  --boxShadowSectionLocationHover: 0 10px 30px 0 rgb(0 0 0 / 10%);
  --boxShadowBottomForm: 0 -2px 4px 0 rgb(0 0 0 / 5%);
  --boxShadowFilterButton: 0 4px 16px 0 rgb(0 0 0 / 20%);

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  --zIndexTopbar: 10;

  /* small popups on UI should use z-indexes above 50 */
  --zIndexPopup: 50;

  /* modals and UI overlays should use z-indexes above 100 */
  --zIndexModal: 100;

  /* ================ Border radius ================ */

  --borderRadius: 2px;
  --borderRadiusMedium: 24px;

  /* ================ Transition styles ================ */

  --transitionStyle: ease-in 0.2s;
  --transitionStyleButton: ease-in-out 0.1s;

  /* ================ Topbar related ================ */

  /* Disable topbar banner height to prevent white padding in some pages when it is not used */

  /* --topbarBannerHeight: 0px; */

  /* Uncomment to enable topbar banner height */

  --topbarBannerHeight: var(--n-size-4);
  /* stylelint-disable-next-line at-rule-empty-line-before */
  @media (width >= 768px) {
    /* Uncomment to enable topbar banner height */

    --topbarBannerHeight: var(--n-size-5);
  }

  /* stylelint-disable-next-line order/order */
  --topbarHeight: 64px;
  /* stylelint-disable-next-line at-rule-empty-line-before */
  @media (width >= 768px) {
    --topbarHeight: 80px;
  }

  --TopbarMobileMenu_topMargin: 96px;
  --Topbar_logoHeight: 25px;
  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;

  /* ================ Mobile bottom nav related ================ */
  --mobileBottomNavHeight: 72px;

  /* ================ Modal default padding ================ */

  --modalPadding: 24px 24px 48px 24px;
  --modalPaddingMedium: 55px 60px 55px 60px;

  /* ================ Filters ================ */
  --marketplaceButtonSmallDesktopPadding: 9px 16px 9px 16px;

  /* ================ SectionHero ================ */
  --SectionHero_desktopTitleMaxWidth: 625px;

  /* ================ TabNav ================ */
  --TabNav_linkWidth: 240px;

  /* ================ Inputs, textareas and selects ================ */
  --borderErrorField: 1px solid var(--colorFail);

  /* ================ LandingPage ================ */
  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;

  /* ================ ProfileSettingsForm ================ */
  --ProfileSettingsForm_avatarSize: 96px;
  --ProfileSettingsForm_avatarSizeDesktop: 240px;
  --ProfileSettingsForm_avatarWidthSize: 96px;
  --ProfileSettingsForm_avatarWidthSizeDesktop: 200px;
  --ProfileSettingsForm_avatarHeightSize: 128px;
  --ProfileSettingsForm_avatarHeightSizeDesktop: 268px;

  /* ================ PageBuilder ================ */

  /* --carouselWidth will be updated dynamically through JS */
  --carouselWidth: 100vw;

  /* NEW VARIABLES */

  --landingPageSectionPadding: 0 5% 40px 5%;

  /* NOLD */
  --n-content-max-width: 1520px;

  /* font sizes */
  --n-font-size-0000: 0.625rem; /* 10px */
  --n-font-size-000: 0.75rem; /* 12px */
  --n-font-size-00: 0.875rem; /* 14px */
  --n-font-size-0-in-px: 16px; /* to prevent iOS zooming on input */
  --n-font-size-0: 1rem; /* 16px */
  --n-font-size-1: 1.125rem; /* 18px */
  --n-font-size-2: 1.25rem; /* 20px */
  --n-font-size-3: 1.5rem; /* 24px */
  --n-font-size-4: 1.875rem; /* 30px */
  --n-font-size-5: 2.25rem; /* 36px */
  --n-font-size-6: 3rem; /* 48px */
  --n-font-size-7: 3.75rem; /* 60px */
  --n-font-size-8: 4.5rem; /* 72px */
  --n-font-size-9: 5.25rem; /* 84px */
  --n-font-size-10: 6rem; /* 96px */

  /* spacing sizes */
  --n-size-0: 0px;
  --n-size-px: 1px;
  --n-size-quarter: 0.125rem; /* 2px */
  --n-size-half: 0.25rem; /* 4px */
  --n-size-1: 0.5rem; /* 8px */
  --n-size-2: 1rem; /* 16px */
  --n-size-3: 1.5rem; /* 24px */
  --n-size-4: 2rem; /* 32px */
  --n-size-5: 2.5rem; /* 40px */
  --n-size-6: 3rem; /* 48px */
  --n-size-7: 3.5rem; /* 56px */
  --n-size-8: 4rem; /* 64px */
  --n-size-9: 4.5rem; /* 72px */
  --n-size-10: 5rem; /* 80px */

  /* border-radius */
  --radius-xs: 4px;
  --radius-sm: 6px;
  --radius-md: 8px;
  --radius-lg: 16px;
  --radius-xl: 24px;
  --radius-xxl: 40px;
  --radius-full: 9999px;

  /* colors */
  --n-color-black: #111112;
  --n-color-text-dark: #393a40;
  --n-color-text-subtle: #676b73;
  --n-color-text-disabled: #9a9da3;
  --n-color-white: #fff;
  --n-color-border: #e8ecf2;
  --n-color-border-hover: #bdc0c7;
  --n-color-border-active: #ced2d9;
  --n-color-accent: #ebfea8;
  --n-color-listing-background: #fff;
  --n-color-custom-green: #a6fe00;
  --n-color-custom-blue: #61cbf4;
  --n-color-custom-red: #f00;
}

#root,
#portal-root {
  isolation: isolate;
}

/* Remove default margin and padding */
* {
  margin: 0;
  padding: 0;
}

/* Avoid text overflows */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/* Remove built-in form typography styles */
input,
button,
textarea,
select {
  font: inherit;
  text-transform: inherit;
}

/* ================ Global element styles ================ */

body {
  font-family: var(--fontFamily);
  font-optical-sizing: auto;
  line-height: 1.5;
  text-rendering: optimizeSpeed;

  background-color: white;

  /* A non-standard feature: https://developer.mozilla.org/en-US/docs/Web/CSS/font-smooth */
  -webkit-font-smoothing: antialiased;
  /* stylelint-disable-next-line property-no-unknown */
  font-smoothing: antialiased;
}

::selection {
  /* WebKit/Blink Browsers */
  color: var(--colorWhite);
  background: var(--marketplaceColor);
}

::-moz-selection {
  /* Gecko Browsers */
  color: var(--colorWhite);
  background: var(--marketplaceColor);
}

a {
  /* Position and dimensions */
  display: inline;

  margin: 0;
  padding: 0;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  text-decoration: underline;
}

html,
li,
p,
pre {
  padding: 0;

  font-family: var(--fontFamily);
  font-size: var(--n-font-size-0);
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
}

/* ================ Normalisations ================ */

html {
  scroll-padding-top: calc(var(--topbarHeight) + 1px);

  margin: 0;
  padding: 0;

  font-size: 87.5%;
  color: var(--n-color-black);

  @media (width >= 768px) {
    scroll-padding-top: calc(var(--topbarHeight) + 1px);
  }
}

/* TODO: What are these styles used for? */
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

label {
  font-size: var(--n-font-size-0);
  font-weight: var(--fontWeightRegular);
  color: var(--n-color-text-dark);
}

/* marketplaceInputFontStyles */
select,
input,
textarea {
  font-family: var(--fontFamily);
  font-size: var(--n-font-size-0-in-px);
}

select {
  /* Dimensions */
  display: block;

  width: 100%;
  padding: 16px var(--n-size-5) 16px var(--n-size-2);

  /* Unset user agent styles */
  appearance: none;
  background-color: #fff;

  /* Background */
  background-image: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSI+CjxtYXNrIGlkPSJtYXNrMF80NzE2XzUwOTkiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+CjxyZWN0IHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iI0Q5RDlEOSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfNDcxNl81MDk5KSI+CjxwYXRoIGQ9Ik0xMi4wMDAxIDE0LjcwMTlMNi42OTIzOCA5LjM5NDIyTDcuNDAwMDggOC42ODY1MkwxMi4wMDAxIDEzLjI4NjVMMTYuNjAwMSA4LjY4NjUyTDE3LjMwNzggOS4zOTQyMkwxMi4wMDAxIDE0LjcwMTlaIiBmaWxsPSIjMUMxQjFGIi8+CjwvZz4KPC9zdmc+Cg==');
  background-position: center right 8px;
  background-size: 24px;
  border: none;
  border-bottom: 1px solid var(--n-color-text-dark);
  outline: none;

  /* Transition */
  transition: all ease-in-out 100ms;

  &::placeholder {
    color: var(--colorGrey300);
  }
}

/* Effects */
select:hover {
  transition: all ease-in-out 100ms;
}

select:focus {
  background-color: #fff;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 25%);
  transition: all ease 100ms;
}

select:disabled {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
  }
}

input,
textarea {
  /* Dimensions */
  display: block;

  width: 100%;
  margin: 0;
  padding: var(--n-size-2);

  -webkit-appearance: none;
  border: unset;
  border-bottom: 1px solid black;
  border-radius: 0;
  outline: none;

  /* Transition */
  transition: all ease-in-out 100ms;

  &::placeholder {
    color: var(--colorGrey300);
  }
}

/* Effects */
input:hover,
textarea:hover {
  transition: all ease-in-out 100ms;
}

input:focus,
textarea:focus {
  /* background-color: #fff; */

  /* border-color: #80bdff; */

  /* box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%); */
  transition: all ease-in-out 100ms;
}

input:disabled,
textarea:disabled {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
  }
}

/* ================ Plain global CSS glasses ================ */

/**
 * Note: even though these reflect element styles,
 * these are below element styles to allow overwrites using classes.
 */

.a {
  /* Position and dimensions */
  display: inline;

  margin: 0;
  padding: 0;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  /* Borders */
  border: none;
}

.a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.p,
.buttonDefault,
.buttonPrimary,
.buttonPrimaryInline,
.buttonSecondary,
.buttonSecondaryInline,
.marketplaceMessageFontStyles,
.marketplaceTxTransitionFontStyles,
.marketplaceListingAttributeFontStyles,
.marketplaceBodyFontStyles,
.marketplaceDefaultFontStyles {
  padding: 0;

  font-family: var(--fontFamily);

  /* No margins for default font */
  font-size: var(--n-font-size-000);
  font-weight: var(--fontWeightMedium);
  line-height: 24px;

  @media (width >= 768px) {
    padding: 5px 0 3px;
    font-size: var(--n-font-size-00);
    line-height: 24px;
  }
}

.p,
.marketplaceBodyFontStyles {
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (width >= 768px) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: var(--n-size-2);
    margin-bottom: var(--n-size-2);
  }
}

/* ================ Other fonts ================ */

.marketplaceSmallFontStyles {
  font-size: var(--n-font-size-00);
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
}

.marketplaceTinyFontStyles {
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;

  font-size: 13px;
  font-weight: var(--fontWeightRegular);
  line-height: 18px;

  -webkit-font-smoothing: subpixel-antialiased;

  @media (width >= 768px) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10.5px;
    margin-bottom: 13.5px;
    line-height: 16px;
  }
}

/* .marketplaceMessageFontStyles is defined earlier */

/* .marketplaceTxTransitionFontStyles is defined earlier */

/* .marketplaceListingAttributeFontStyles is defined earlier */

.marketplaceMessageDateFontStyles {
  font-size: var(--n-font-size-000);
  font-weight: var(--fontWeightRegular);
  line-height: 18px;

  @media (width >= 768px) {
    font-size: var(--n-font-size-000);
    font-weight: var(--fontWeightMedium);
    line-height: 24px;
  }
}

.marketplaceSearchFilterLabelFontStyles {
  font-size: 13px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 18px;

  @media (width >= 768px) {
    font-size: var(--n-font-size-0);
    font-weight: var(--fontWeightMedium);
    line-height: 20px;
  }
}

.marketplaceSearchFilterSublabelFontStyles {
  font-size: var(--n-font-size-1);
  font-weight: var(--fontWeightMedium);
  line-height: 18px;
}

/* ================ Global class for input styles ================ */

.marketplaceInputStyles {
  /* Dimensions */
  display: block;

  width: 100%;
  margin: 0;

  /* 6 + 24 + 4 + borders are divisible by 6 */
  padding: 6px 12px 4px;

  background-color: #fff;
  border-bottom: 1px solid #d8dce6;
  outline: none;

  transition: all ease-in-out 100ms;

  &::placeholder {
    color: var(--colorGrey300);
  }

  @media (width >= 768px) {
    /* 7 + 32 + 7 + borders = 48, which is divisible by 8. */
    padding: 7px var(--n-size-2);
    line-height: 32px;
  }
}

/* Effects */
.marketplaceInputStyles:hover {
  border: 1px solid #b8bfd1;
  transition: all ease-in-out 100ms;
}

.marketplaceInputStyles:focus {
  background-color: #fff;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  transition: all ease-in-out 100ms;
}

.marketplaceInputStyles:disabled {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
  }
}

/* ================ Tabbed navigation font styles ================ */

.marketplaceTabNavFontStyles {
  font-size: var(--n-font-size-0);
  font-weight: var(--fontWeightMedium);
  line-height: 24px;

  @media (width >= 1024px) {
    font-size: var(--n-font-size-2);
    font-weight: var(--fontWeightSemiBold);
    line-height: 24px;
  }
}

.marketplaceTabNavHorizontalFontStyles {
  font-size: var(--n-font-size-00);
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
}

/* ================ Buttons ================ */

/**
 * For actual buttons, use components: <Button>, <PrimaryButton>, <SecondaryButton>
 *
 * To create button look-and-feel for links, etc. combine button classes in CSS Modules:
 *
 * <Button>:
 *   composes: buttonDefault from global;
 * <PrimaryButton>:
 *   composes: buttonPrimary from global;
 * <SecondaryButton>:
 *   composes: buttonSecondary from global;
 * <SecondaryButtonInline>:
 *   composes: buttonSecondaryInline from global;
 */

/**
 * Styles for <Button>
 */
.buttonDefault {
  cursor: pointer;

  /* button defaults */
  display: block;

  width: 100%;
  height: 48px;
  margin: 0;

  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  padding: 12px 0;

  /*
   * Font: most font styles are defined earlier in this file
   */
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorWhite);

  /* Button text styles */

  /* Default button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Button colors */

  /* Default button uses marketplace color */
  background-color: var(--marketplaceColor);

  /* Button borders */

  /* Default button has no borders */
  border: none;
  border-radius: var(--borderRadiusMedium);

  transition: all var(--transitionStyleButton);
}

.buttonDefault:focus,
.buttonDefault:hover {
  color: #000;
  text-decoration: none;

  background-color: white;
  border: 1px solid black;
  outline: none;
}

.buttonDefault:disabled {
  cursor: not-allowed;

  color: var(--colorWhite);
  text-decoration: none;

  background-color: var(--colorGrey100);
  box-shadow: none;
}

/**
 * Styles for <PrimaryButton>
 */
.buttonPrimary {
  cursor: pointer;

  /* button defaults */
  display: block;

  width: 100%;
  min-height: 56px;
  margin: 0;

  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  padding: var(--n-size-2) 0;

  /*
   * Font: most font styles are defined earlier in this file
   */
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorWhite);

  /* Button text styles */

  /* Primary button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Button colors */

  /* Primary button uses success color */
  background-color: var(--marketplaceColor);

  /* Button borders */

  /* Primary button has no borders */
  border: none;
  border-radius: var(--borderRadiusMedium);

  transition: all var(--transitionStyleButton);

  @media (width >= 768px) {
    padding: 20px 0;
  }
}

.buttonPrimary:focus,
.buttonPrimary:hover {
  color: var(--colorWhite);
  background-color: var(--colorSuccessDark);
  outline: none;
  box-shadow: var(--boxShadowButton);
}

.buttonPrimary:hover {
  text-decoration: none;
}

.buttonPrimary:disabled {
  cursor: not-allowed;
  color: var(--colorGrey700);
  background-color: var(--colorGrey100);
  box-shadow: none;
}

/**
 * Styles for <PrimaryButtonInline>
 */
.buttonPrimaryInline {
  cursor: pointer;

  display: inline-block;

  margin: 0;
  padding: var(--n-size-1) var(--n-size-2);

  /* Font */
  font-size: 13px;
  font-weight: var(--fontWeightMedium);
  line-height: 18px;
  color: var(--colorWhite);

  /* Button text styles */
  text-align: center;
  text-decoration: none;

  background-color: var(--colorSuccess);

  /* Button borders */
  border: none;
  border-radius: var(--borderRadiusMedium);

  transition: all var(--transitionStyleButton);

  @media (width >= 768px) {
    line-height: 16px;
  }
}

.buttonPrimaryInline:focus,
.buttonPrimaryInline:hover {
  color: var(--colorWhite);
  text-decoration: none;

  background-color: var(--colorSuccessDark);
  outline: none;
  box-shadow: var(--boxShadowButton);
}

.buttonPrimaryInline:disabled {
  cursor: not-allowed;
  color: var(--colorGrey700);
  background-color: var(--colorGrey100);
  box-shadow: none;
}

/**
 * Styles for <SecondaryButton>
 */
.buttonSecondary {
  cursor: pointer;

  /* button defaults */
  display: block;

  width: 100%;
  min-height: 56px;
  margin: 0;

  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  padding: var(--n-size-2) 0;

  /*
   * Font: most font styles are defined earlier in this file
   */
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorGrey700);

  /* Button text styles */

  /* Secondary button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Button colors: secondary */
  background-color: var(--colorWhite);
  border-color: var(--colorGrey100);
  border-style: solid;

  /* Secondary button has clear borders */
  border-width: 1px;
  border-radius: var(--borderRadiusMedium);

  transition: all var(--transitionStyleButton);

  @media (width >= 768px) {
    padding: 20px 0;
  }
}

.buttonSecondary:focus,
.buttonSecondary:hover {
  background-color: var(--colorWhite);
  border-color: var(--colorGrey300);
  outline: none;
  box-shadow: var(--boxShadowButton);
}

.buttonSecondary:hover {
  text-decoration: none;
}

.buttonSecondary:disabled {
  cursor: not-allowed;

  color: var(--colorWhite);

  background-color: var(--colorGrey100);
  border-color: var(--colorGrey100);
  box-shadow: none;
}

/**
 * Styles for <SecondaryButtonInline>
 */
.buttonSecondaryInline {
  cursor: pointer;

  /* button defaults */
  display: inline-block;

  width: auto;
  height: 42px;
  min-height: auto;
  margin: 17px 0 0;

  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  padding: 0 var(--n-size-2);

  font-size: var(--n-font-size-00);

  /*
   * Font
   */
  font-weight: var(--fontWeightMedium);
  line-height: 18px;
  color: var(--colorGrey700);

  /* Button text styles */

  /* Secondary button text is centered without decorations */
  text-align: center;
  text-decoration: none;
  letter-spacing: 0;

  /* Button colors: secondary */
  background-color: var(--colorWhite);
  border-color: var(--colorGrey100);
  border-style: solid;

  /* Secondary button has clear borders */
  border-width: 1px;
  border-radius: var(--borderRadiusMedium);

  transition: all var(--transitionStyleButton);

  @media (width >= 768px) {
    height: 40px;
  }
}

.buttonSecondaryInline:focus,
.buttonSecondaryInline:hover {
  background-color: var(--colorWhite);
  border-color: var(--colorGrey300);
  outline: none;
  box-shadow: var(--boxShadowButton);
}

.buttonSecondaryInline:hover {
  text-decoration: none;
}

.buttonSecondaryInline:disabled {
  cursor: not-allowed;
  border-color: var(--colorGrey100);
  box-shadow: none;
}

/* ================ Modals ================ */

.marketplaceModalRootStyles {
  /* AuthenticationPage's root uses flexbox */
  display: flex;

  /* Page is using flex: AuthenticationPage's .root takes all available space */
  flex-grow: 1;

  @media (width >= 768px) {
    align-items: flex-start;
    justify-content: center;
  }
}

.marketplaceModalFormRootStyles {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
}

.marketplaceModalBaseStyles {
  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: var(--modalPadding);

  background-color: var(--colorWhite);
  border-bottom: none;
  border-radius: var(--borderRadius);

  @media (width >= 768px) {
    flex-basis: 480px;
    flex-grow: 0;

    margin-top: 12.5vh;
    margin-bottom: 12.5vh;

    /* min-height: 568px; */
    padding: var(--modalPaddingMedium);

    /* border-bottom: 8px solid var(--marketplaceColor); */
  }
}

.marketplaceModalInMobileBaseStyles {
  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  height: 100vh;
  padding: 0;

  background-color: var(--colorWhite);
  border-radius: var(--borderRadius);

  @media (width >= 768px) {
    flex-basis: 480px;
    flex-grow: 0;

    height: 100%;
    padding: 0;

    background-color: transparent;
    border-radius: 0;
  }
}

/* Modal title */
.marketplaceModalTitleStyles {
  margin: 0;
  font-size: var(--n-font-size-4);
  font-weight: var(--fontWeightBold);
  line-height: 36px;

  @media (width >= 768px) {
    margin: 0;
    font-weight: var(--fontWeightBold);
    line-height: 40px;
  }
}

/* Modal message */
.marketplaceModalParagraphStyles {
  margin: 18px 0 0;
  font-size: var(--n-font-size-2);
  font-weight: var(--fontWeightMedium);
  line-height: 32px;

  @media (width >= 768px) {
    margin: var(--n-size-3) 0 0;
  }
}

/* Bottom wrapper for the modal */
.marketplaceModalBottomWrapper {
  align-self: stretch;
  margin-top: 60px;
  text-align: center;

  @media (width >= 768px) {
    margin-top: 96px;
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.marketplaceModalBottomWrapperText {
  margin-top: calc(-1 * var(--n-size-3));
  padding-bottom: var(--n-size-1);

  @media (width >= 768px) {
    padding-bottom: 0;
  }
}

.marketplaceModalHelperText {
  margin: 0;

  font-size: 13px;
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
  color: var(--colorGrey300);

  -webkit-font-smoothing: subpixel-antialiased;

  @media (width >= 768px) {
    margin: 0;
    line-height: 16px;
  }
}

.marketplaceModalHelperLink {
  margin: 0;

  font-size: 13px;
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
  color: var(--colorGrey700);

  -webkit-font-smoothing: subpixel-antialiased;

  @media (width >= 768px) {
    margin: 0;
    line-height: 16px;
  }
}

/* Modal Close icon */

.marketplaceModalCloseStyles {
  cursor: pointer;

  /* Position inside modal */
  position: fixed;

  /* Some content (like map) is positioning itself on top of close button without z-index handling */
  z-index: calc(var(--zIndexModal) + 1);
  top: 0;
  right: 0;

  /* Safari didn't know how to baseline-align svg icon and text */
  display: flex;
  align-items: flex-start;

  width: auto;
  margin: 0;

  /* Sizing (touch area) */
  padding: var(--n-size-3);

  color: var(--colorGrey700);

  /* Colors */
  background-color: transparent;
  border: 0;

  @media (width >= 768px) {
    position: absolute;
    padding: 27px 30px;
  }
}

.marketplaceModalCloseStyles:enabled:hover {
  color: var(--colorBlack);
  text-decoration: none;
  background-color: transparent;
  box-shadow: none;
}

.marketplaceModalCloseStyles:enabled:active {
  color: var(--colorBlack);
  background-color: transparent;
}

.marketplaceModalCloseStyles:disabled {
  background-color: transparent;
}

.marketplaceModalIconStyles {
  height: 48px;
  margin-bottom: 12px;

  @media (width >= 768px) {
    height: 64px;
    margin-bottom: 23px;
  }
}

.marketplaceModalCloseText {
  margin: 0;

  font-size: var(--n-font-size-000);

  /* Font */
  font-weight: var(--fontWeightBold);
  line-height: 18px;
  text-transform: uppercase;

  @media (width >= 768px) {
    margin: 0;
    line-height: 16px;
  }
}

.marketplaceModalCloseIcon {
  display: inline-block;
  box-sizing: content-box;

  /* Move X icon vertically to align it with the close text. */
  margin-top: 3px;
  margin-left: var(--n-size-1);

  @media (width >= 768px) {
    margin-top: 2px;
  }
}

.marketplaceModalPasswordMargins {
  /* Leave space between the input and the button below when the
  viewport height is small */
  margin-top: var(--n-size-3);

  @media (width >= 768px) {
    margin-top: var(--n-size-4);
  }
}
