@import url('../../styles/customMediaQueries.css');

.root {
  overflow-x: hidden;
  font-family: Syne, sans-serif;
}

.stepperMobileTitle {
  margin: var(--n-size-2) 0;

  font-size: var(--n-font-size-2);
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.stepperItems {
  scroll-snap-type: x mandatory;

  isolation: isolate;

  overflow-x: visible;
  display: flex;
  grid-gap: var(--n-size-2);
  justify-content: space-between;

  max-width: 1224px;
  margin: var(--n-size-3) auto 42px;
  padding: 0 var(--n-size-3);

  text-transform: uppercase;

  &::-webkit-scrollbar {
    display: none;
  }
}

.stepperItem {
  scroll-snap-align: center;

  position: relative;

  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--n-size-2);
  align-items: center;
}

.stepperItem::after {
  content: '';

  position: absolute;
  z-index: 2;
  top: calc(16px - 1px);
  left: calc(50% + 9px);

  width: 100vw;
  height: 2px;

  background: var(--n-color-border);
}

.stepperItem:first-child::before {
  content: '';

  position: absolute;
  z-index: 2;
  top: calc(16px - 1px);
  left: -50vw;

  width: 100vw;
  height: 2px;

  background: var(--n-color-black);
}

.stepperItem.completed::after {
  background: var(--n-color-black);
}

.stepCounter {
  z-index: 5;

  display: grid;
  place-items: center;

  width: 32px;
  height: 32px;

  /* body/2/semibold */
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-000);
  font-weight: 600;
  font-style: normal;
  line-height: 16px; /* 133.333% */
  color: #0b0f1f;

  background-color: white;
  border: 2px solid var(--n-color-border);
  border-radius: 50%;
}

.stepperItem.active .stepCounter {
  color: white;
  background-color: var(--n-color-black);
  border-color: var(--n-color-black);
}

.stepperItem.completed .stepCounter {
  color: white;
  background-color: var(--n-color-black);
  border-color: var(--n-color-black);
}

.stepName {
  font-size: var(--n-font-size-0);
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-subtle);
  text-align: center;
  word-wrap: break-word;
  white-space: nowrap;
}

.stepperItem.active .stepName {
  color: var(--n-color-black);
}
