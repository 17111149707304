@import url('../../../styles/customMediaQueries.css');

.root {
  display: grid;
  gap: var(--n-size-6);
  padding-top: var(--n-size-4);
  padding-bottom: var(--n-size-4);
}

.section {
  display: grid;
  gap: var(--n-size-4);
}

.section__title {
  font-family: Syne, sans-serif;
  font-size: var(--n-size-3);
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  @media (width >= 768px) {
    text-align: left;
  }
}

.divider {
  height: 1px;
  background: var(--n-color-border);
}

.noldSectionContent {
  display: grid;
  gap: var(--n-size-2);
}

.cardWrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--n-size-4);
  align-content: center;
  align-items: center;

  @media (width >= 768px) {
    grid-template-columns: auto 1fr auto;
  }
}

.card {
  display: grid;
  align-content: center;

  aspect-ratio: 1.586;
  width: 140px;
  padding: var(--n-size-2);

  font-family: Syne, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  background: var(--n-color-black);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid var(--n-color-black);
  border-radius: var(--radius-lg);

  @media (width >= 768px) {
    width: 200px;
    padding: var(--n-size-4);
    font-size: var(--n-font-size-3);
  }
}

.amount {
  font-family: Montserrat, sans-serif;
  font-size: var(--n-size-4);
  letter-spacing: 0.1em;
}

.availableAtCheckout {
  color: var(--n-color-text-subtle);
}

.empty {
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-2);
  text-align: center;

  @media (width >= 768px) {
    text-align: left;
  }
}

.secondaryInfo {
  display: grid;
  grid-column: 1 / -1;
  color: var(--n-color-text-subtle);

  @media (width >= 768px) {
    grid-column: unset;
    gap: var(--n-size-2);
    text-align: right;
  }
}
