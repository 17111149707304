.root {
  background-position: center;
  background-size: cover;
  filter: blur(10px);
  transition: filter 0.2s;
}

.loaded {
  filter: blur(0);
}
