@import url('../../styles/customMediaQueries.css');

.root {
  display: flex;
  flex-direction: row;

  /* overflow-x: auto; */
}

.darkSkin {
  background-color: var(--colorGrey700);
}

.tab {
  width: 100%;

  /* margin-left: var(--n-size-2); */

  /* &:first-child {
    margin-left: 0;
  }

  @media (width >= 1024px) {
    margin-left: var(--n-size-3);
  } */
}

.tabContent {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  width: 100%;
  padding: 17px 30px;

  font-size: var(--n-font-size-0);
  color: black;
  text-align: center;

  &:hover,
  &:focus {
    color: var(--colorBlack);
    text-decoration: none;
  }

  /* composes: a from global;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  font-weight: var(--fontWeightMedium);
  font-size: var(--n-font-size-00);
  line-height: 24px;

  color: var(--colorGrey700);
  padding-bottom: 10px;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--colorBlack);
  }

  @media (width >= 768px) {
    border-bottom-width: 4px;
    padding: var(--n-size-2) 10px 14px 10px;
  } */
}

.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;

  /* Override Chrome's button outline */
  outline: 0;
}

.selectedTabContent {
  /* border-bottom-color: var(--colorBlack); */
  color: var(--colorBlack);
  border-bottom: 2px solid var(--n-color-text-dark) !important;
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
  color: var(--colorGrey300);

  &:hover {
    color: var(--colorWhite);
  }
}

.selectedTabContentDarkSkin {
  color: var(--colorWhite);
  border-bottom-color: var(--colorWhite);
}

.disabledDarkSkin {
  color: var(--colorBlack);
  opacity: 0.3;
}
