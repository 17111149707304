@import url('../../../styles/customMediaQueries.css');

.listingsContainer {
  width: 100%;
  margin-top: var(--n-size-2);
}

.listings {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: var(--n-size-3);
  margin: var(--n-size-2) 0;

  @media (width >= 768px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  @media (width >= 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.listing {
  display: block;

  & img {
    aspect-ratio: 3 / 4;
  }
}

.spinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--n-size-4) 0;
}
