@import url('../../styles/customMediaQueries.css');

.sectionContainer {
  display: grid;

  width: 100%;
  max-width: var(--n-content-max-width);
  margin: 0 auto;
  padding: var(--n-size-4) var(--n-size-2);

  @media (width >= 1024px) {
    padding: var(--n-size-4) var(--n-size-10);
  }
}

.emptyStateWithButton {
  display: grid;
  gap: var(--n-size-5);
  justify-items: center;

  padding: var(--n-size-10) var(--n-size-2);

  font-family: Montserrat;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  text-align: center;
}
