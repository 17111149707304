@import url('../../styles/customMediaQueries.css');

.root {
  overflow-x: auto;
  display: flex;
  flex-direction: row;
}

.tab {
  margin-left: var(--n-size-2);

  &:first-child {
    margin-left: 0;
  }

  @media (width >= 1024px) {
    margin-left: var(--n-size-3);
  }
}

.link {
  display: inline-block;

  /* push tabs against bottom of tab bar */
  margin-top: auto;
  padding-top: 10px;
  padding-bottom: 10px;

  color: var(--colorGrey700);
  white-space: nowrap;

  background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
  background-position: calc(var(--TabNav_linkWidth) + 4px) center; /* SelectedLink's width (200px) + border thickness (4px) = 204px */
  border-bottom: 2px solid transparent;

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;
  }

  @media (width >= 1024px) {
    width: var(--TabNav_linkWidth);
    margin-top: 0;
    margin-bottom: var(--n-size-2);
    padding-top: 5px; /* Drop the text to adjust it to correct baseline */
    padding-bottom: 3px;

    font-size: var(--n-font-size-2);
    font-weight: var(--fontWeightSemiBold);
    line-height: 24px;

    border-bottom-width: 0;

    &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
      background-position: right center;
    }
  }
}

.selectedLink {
  color: var(--colorBlack);
  border-bottom-color: var(--colorBlack);

  @media (width >= 1024px) {
    width: var(--TabNav_linkWidth);
    background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");
    background-position: right center;

    &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
      background-position: right center;
    }
  }
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}
