@import url('../../styles/customMediaQueries.css');

.pageTitle {
  margin: var(--n-size-3) 0;

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-0);
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.topBar {
  border-top: 1px solid var(--n-color-border);
  border-bottom: 1px solid var(--n-color-border);
}

.topBar__content {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;

  width: 100%;
  max-width: var(--n-content-max-width);
  margin: 0 auto;
  padding: 0 var(--n-size-10);

  font-family: var(--fontFamilySyne);
  font-weight: 700;
  text-transform: uppercase;

  & h1,
  & h2 {
    padding: var(--n-size-3) var(--n-size-2);
    font-size: var(--n-font-size-0);
  }

  & h1 {
    border-right: 1px solid var(--n-color-border);
  }
}

.tabs {
  overflow: hidden;
}

.tabs__list {
  scrollbar-width: none;

  overflow-x: auto;
  display: flex;
  gap: var(--n-size-1);

  width: 100%;
  margin: 0 auto;

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (width >= 1024px) {
    flex-direction: column;
  }
}

.tabs__trigger {
  flex-shrink: 0;

  min-width: 33.3333%;
  padding: var(--n-size-1) var(--n-size-2);

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-00);
  font-weight: 700;
  color: var(--n-color-text-disabled);
  text-align: center;
  text-transform: uppercase;

  border: none;
  border-bottom: 2px solid transparent;

  &:hover {
    text-decoration: none;
  }

  &[data-state='active'] {
    color: var(--n-color-black);
    background-color: var(--n-color-background);
    border-bottom-color: var(--n-color-black);
  }

  @media (width >= 1024px) {
    padding: var(--n-size-3) var(--n-size-2);
    text-align: left;
    border: none;

    &[data-state='active'] {
      background: #f5f7fa;
      border-right: 2px solid var(--n-color-black);
    }
  }
}

.tabs__contentWrapper {
  @media (width >= 1024px) {
    display: grid;
    grid-template-columns: 250px 1fr;
    align-content: start;
    align-items: start;

    width: 100%;
    max-width: var(--n-content-max-width);
    margin: 0 auto;
    padding: 0 var(--n-size-10);
  }
}
