@import url('../../../styles/customMediaQueries.css');

.avatarLayout {
  padding: var(--n-size-3) 0;

  @media (width >= 768px) {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0 var(--n-size-4);
    padding-bottom: 30px;
  }
}

.avatar {
  && {
    grid-row: 1 / span 3;
    width: 80px;
    height: 80px;

    @media (width >= 768px) {
      width: 160px;
      height: 160px;
    }
  }
}

.stats {
  display: grid;
  grid-auto-flow: column;
  gap: var(--n-size-1);
  align-items: center;
  justify-content: start;

  /* Body/Body 2/Regular */
  font-family: Montserrat;
  font-size: var(--n-font-size-00);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
}

.bio {
  font-family: Montserrat;
  font-size: var(--n-font-size-00);
  font-weight: 400;
  line-height: 20px;
}

.impactAndShareContainer {
  display: grid;
  gap: var(--n-size-2);

  @media (width >= 768px) {
    grid-template-columns: 1fr auto;
  }
}

.share {
  display: grid;
  grid-template-columns: repeat(3, auto);
  row-gap: var(--n-size-1);
  justify-content: center;
  justify-items: end;

  /* Body/Body 2/Semi-bold */
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-00);
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);

  & > *:first-child {
    grid-column: 1 / span 3;
  }

  @media (width >= 768px) {
    align-content: end;
    padding-bottom: var(--n-size-2);
  }
}

.verticalDivider {
  display: inline-block;
  width: 1px;
  height: 24px;
  background: var(--n-color-border-active);

  @media (width <= 385px) {
    display: none;
  }
}

.infoWrapper {
  display: flex;
  align-items: center;
}

.name {
  margin: 0;
  padding: 0;

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-3);
  font-weight: 700;
  line-height: 1.5;
  color: var(--n-color-text-dark);
  text-transform: uppercase;
}

.handle {
  margin: 0;
  padding: 0;

  font-family: Montserrat;
  font-size: var(--n-font-size-0);
  font-weight: 600;
  line-height: 1.5;
}

.profileRatingWrapper {
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--n-size-1);
  align-items: center;
  justify-content: space-between;

  @media (width <= 385px) {
    grid-template-columns: auto;
  }
}

.profileHeading {
  display: grid;
  gap: var(--n-size-2);
  color: var(--n-color-text-dark);
}

.mainInfo {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(3, auto);
  gap: 4px var(--n-size-2);
  align-content: start;

  .stats {
    display: none;
  }

  @media (width >= 768px) {
    grid-template-columns: 1fr;

    .starsContainer {
      display: grid;
      grid-template-columns: auto auto;
    }

    .stats {
      display: grid;
      margin: 3px 0 0 var(--n-size-1);

      .verticalDivider {
        display: inline-block;
      }
    }
  }
}

.secondaryInfo {
  display: grid;
  gap: var(--n-size-2);

  .verticalDivider:first-child {
    display: none;
  }

  @media (width >= 768px) {
    .stats {
      display: none;
    }
  }
}

.shareButton {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: var(--n-size-2);
  padding: 0;

  background: transparent;
  border: none;
}

.shareButton::-moz-focus-inner {
  border: 0;
}
