@import url('../../styles/customMediaQueries.css');

.root {
  isolation: isolate;
  position: relative;
  overflow: hidden;
  background: var(--n-color-white);
}

.imagesWrapper {
  --max-height: min(450px, calc(100vh - var(--topbarHeight) - var(--mobileBottomNavHeight)));

  scroll-snap-type: x mandatory;

  overflow: scroll hidden;
  overscroll-behavior-x: contain;
  display: flex;
  gap: var(--n-size-2);
  align-items: center;

  max-height: var(--max-height);
  padding: 0 var(--n-size-2);

  -webkit-overflow-scrolling: touch;

  & > img {
    scroll-snap-align: center;

    overflow: hidden;
    display: inline-block;
    flex: 0 0 auto;

    width: auto;
    max-width: 85vw;

    /* I have no idea why we need var(--n-size-3) here. If someone gets this please explain. */
    max-height: calc(var(--max-height) - var(--n-size-3));

    border-radius: var(--radius-md);

    /* &:first-child {
      width: calc(0.75 * var(--max-height));
      height: var(--max-height);
      object-fit: contain;
    } */

    @media (width >= 768px) {
      max-width: 45vw;
    }
  }

  @media (width >= 768px) {
    --max-height: min(800px, calc(100vh - var(--topbarHeight) - var(--mobileBottomNavHeight)));
  }

  @media (width >= 1024px) {
    padding: 0;
  }
}

.imagesWrapper::-webkit-scrollbar {
  background: transparent;
}

.imagesWrapperSingle {
  justify-content: center;
}

.sliderButtons {
  position: absolute;
  top: 50%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.prevButton,
.nextButton {
  cursor: pointer;

  position: absolute;
  z-index: 10;
  top: calc(50% - 50px);

  display: none;

  padding: var(--n-size-1);

  border-color: transparent;
  border-radius: 2em;

  & svg {
    width: 48px;
    height: 48px;
  }

  @media (width >= 1024px) {
    display: block;
  }
}

.nextButton {
  right: 0;
}

.sliderButtonsHidden {
  display: none;
}

/* SingleProductGalleryModal */

.dialog__overlay {
  position: fixed;
  inset: 0;
  background: var(--n-color-white);
}

.dialog__content {
  isolation: isolate;
  position: fixed;
  inset: 0;

  display: grid;
  place-content: center;

  outline: none;
}

.dialog__close {
  cursor: pointer;

  position: absolute;
  top: 16px;
  right: 16px;

  width: 24px;
  height: 24px;

  background-size: contain;
}

.dialog__title {
  margin: 0;
  padding: var(--n-size-2) 0;

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-0);
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0;
}

.dialog__content .imagesWrapper {
  padding: 0 var(--n-size-2);

  & > img {
    @media (width >= 768px) {
      max-width: 90vw;
    }
  }
}
