.button {
  position: absolute;
  top: calc(50% - 20px);
  align-self: center;

  & svg {
    width: 40px;
    min-width: unset;
    height: 40px;
    padding: var(--n-size-1);

    background-color: var(--n-color-white);
    border-radius: var(--radius-full);
    box-shadow: 0 0 32px rgb(0 0 0 / 15%);
  }
}
