.root {
  display: grid;
  gap: var(--n-size-3);
}

.title {
  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-3);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
}

.listing {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: var(--n-size-2);

  & > img {
    aspect-ratio: 3 / 4;
    max-height: 130px;
    object-fit: cover;
  }
}

.listing__data {
  display: grid;
  gap: var(--n-size-1);
  align-content: center;

  & > *:first-child {
    /* Body/Body 1/Regural */
    font-family: Montserrat;
    font-size: var(--n-font-size-0);
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    color: var(--n-color-text-dark);
  }

  & > *:last-child {
    /* Body/Body 1/Bold */
    font-family: Montserrat;
    font-size: var(--n-font-size-0);
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    color: var(--n-color-text-dark);
  }
}

.buttonsContainer {
  display: grid;
  gap: var(--n-size-2);
}

.button {
  display: grid;
  grid-template-rows: auto auto;
  gap: var(--n-size-1) var(--n-size-2);
  justify-items: center;

  /* +1 to account for change of border size */
  padding: calc(var(--n-size-3) + 1px);

  text-align: center;

  background: var(--n-color-white);
  border: 1px solid var(--n-color-border-active);
  border-radius: var(--radius-md);

  &[data-active='true'] {
    padding: var(--n-size-3);
    color: var(--n-color-black);
    border: 2px solid var(--n-color-black);
  }

  & > *:nth-child(1) {
    /* Heading/H4 */
    font-family: var(--fontFamilySyne);
    font-size: var(--n-font-size-3);
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    color: var(--n-color-text-dark);
  }

  & > *:nth-child(2) {
    /* Body/Body 1/Regural */
    font-family: Montserrat;
    font-size: var(--n-font-size-0);
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    color: var(--n-color-text-dark);
  }
}

.button__icon {
  display: grid;
  place-content: center;
  align-self: start;

  padding: var(--n-size-1);

  color: white;

  background: var(--n-color-black);
  border-radius: 4px;
}

.disclaimer {
  margin: 0;
  padding: 0;

  /* Body/Body 2/Regular */
  font-family: Montserrat;
  font-size: var(--n-font-size-00);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-subtle);
}

.actionButtons {
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--n-size-2);
  justify-content: space-between;
}
