.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--n-size-1);
  align-items: stretch;

  padding: var(--n-size-2);

  background-color: rgb(255 255 255 / 80%);
  backdrop-filter: blur(4px);
  border-radius: var(--radius-md);
  box-shadow: 0 0 32px 0 rgb(0 0 0 / 10%);
}

.buttonContainer {
  display: flex;
  gap: var(--n-size-2);
  justify-content: flex-end;
  margin-top: var(--n-size-2);
}

.button {
  cursor: pointer;

  display: flex;
  gap: var(--n-size-1);
  align-items: center;
  justify-content: center;

  padding: var(--n-size-1) var(--n-size-2);

  font-family: Syne, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 700;
  line-height: 19px;
  color: var(--text-default-subtle);
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;
  letter-spacing: 0;

  background: transparent;
  border: none;

  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: rgb(255 255 255 / 20%);
}

.buttonItem {
  cursor: pointer;

  display: flex;
  gap: var(--n-size-1);
  align-items: center;
  justify-content: center;

  padding: var(--n-size-1) var(--n-size-2);

  font-family: Syne, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 700;
  line-height: 19px;
  color: var(--text-default-subtle);
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;
  letter-spacing: 0;

  background: transparent;
  border: none;
  border-radius: var(--radius-xs);

  transition: background-color 0.3s ease;
}

.buttonItem:hover {
  background-color: rgb(128 128 128 / 20%);
}

.modalButtons {
  display: flex;
  gap: var(--n-size-2);
  justify-content: space-between;
  margin-top: var(--n-size-4);
}

.pendingModalButtons {
  display: flex;
  gap: var(--n-size-2);
  justify-content: right;
  margin-top: var(--n-size-4);
}

.modalButton {
  display: grid;
  gap: var(--n-size-2);
  width: 200px;
  padding: var(--n-size-2);
}
