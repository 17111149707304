@import url('../../styles/customMediaQueries.css');

.overlay {
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 50%);
  backdrop-filter: blur(8px);
}

.card {
  --modalCardPadding: var(--n-size-2);

  position: fixed;
  inset: 0;

  display: grid;
  grid-template-rows: auto 1fr;

  background-color: white;

  @media (width >=768px) {
    --modalCardPadding: var(--n-size-4);

    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    max-width: 500px;
    max-height: calc(100% - 2 * var(--n-size-2));

    border-radius: var(--radius-xxl);
  }
}

.title {
  margin: 0;
  padding: var(--modalCardPadding) calc(3 * var(--modalCardPadding)) var(--modalCardPadding)
    var(--modalCardPadding);

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-2);
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
  text-transform: uppercase;
}

.description {
  width: 90%;
  margin-bottom: var(--n-size-4);
  margin-left: var(--n-size-4);
}

.closeButton {
  cursor: pointer;

  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;

  padding: var(--modalCardPadding);

  border: none;
}

.content {
  overflow: auto;
  margin-bottom: var(--modalCardPadding);
  padding: 0 var(--modalCardPadding);

  &[data-has-footer='true'] {
    margin-bottom: 0;
  }
}

.footer {
  padding: var(--modalCardPadding);
}
