.rating {
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--n-size-1);
  align-items: center;
  justify-content: start;
}

.stars {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & > * {
    margin: 0 -2px;
  }
}

.number {
  margin-bottom: -4px;
  font-size: var(--n-font-size-00);
  font-weight: 600;
}
