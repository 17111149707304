.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.topbar {
  position: sticky;
  z-index: var(--zIndexTopbar);
  top: 0;
}

.main {
  isolation: isolate;
  display: grid;
}

.footer {
  position: sticky;
  bottom: 0;
}
