@import url('../../styles/customMediaQueries.css');

.checkboxRoot {
  overflow: hidden;
  display: inline-grid;
  place-items: center;

  width: 15px;
  height: 15px;
  margin: 0;
  padding: 0;

  background: var(--n-color-white);
  border: 1.5px solid var(--n-color-black);

  transition: all 0.1s ease;

  &[data-state='checked'] {
    color: white;
    background-color: var(--n-color-black);
  }
}

.checkboxIndicator {
  position: relative;
}

.checkboxLabel {
  display: inline-grid;
  grid-template-columns: auto 1fr;
  gap: var(--n-size-1);
  align-items: center;

  /* Body/Body 1/Regural */
  font-family: Montserrat;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
}
