@import url('../../styles/customMediaQueries.css');

.content {
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: var(--n-size-4);
  width: 100%;

  & > *:first-child {
    justify-self: start;
    margin-bottom: calc(-1 * var(--n-size-2));
  }
}

.buttonsContainer {
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--n-size-1);
  justify-content: end;
}
