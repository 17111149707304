@import url('../../styles/customMediaQueries.css');

.root {
  display: flex;
  flex-direction: row;
}

.darkSkin {
  background-color: var(--colorGrey700);
}

.tab {
  width: 100%;
}

.tabContent {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  width: 22vw;
  padding: 20px 30px 20px 15px;

  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  color: var(--text-default-hover, var(--n-color-black));

  border-left: 2px solid transparent;

  &:hover,
  &:focus {
    color: var(--colorBlack);
    text-decoration: none;
  }

  @media (width >= 1024px) {
    width: 15vw;
  }
}

.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;

  /* Override Chrome's button outline */
  outline: 0;
}

.selectedTabContent {
  color: var(--colorBlack);
  border-left-color: var(--n-color-black);
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
  color: var(--colorGrey300);

  &:hover {
    color: var(--colorWhite);
  }
}

.selectedTabContentDarkSkin {
  color: var(--colorWhite);
  border-bottom-color: var(--colorWhite);
}

.disabledDarkSkin {
  color: var(--colorBlack);
  opacity: 0.3;
}
