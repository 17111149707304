@import url('../../styles/customMediaQueries.css');

.root {
  position: fixed;
  right: var(--n-size-3);
  bottom: var(--n-size-3);

  display: none;

  padding: var(--n-size-2);

  background: white;
  border: none;
  border-radius: var(--radius-full);
  box-shadow: 0 0 8px rgb(0 0 0 / 10%);

  transition:
    box-shadow 0.2s,
    transform 0.2s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 12px rgb(0 0 0 / 15%);
  }

  @media (width >= 768px) {
    display: block;
  }
}
