@import url('../../styles/customMediaQueries.css');

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

/* Styles for close icon above the picture */
.root > div > div > div > svg {
  position: absolute;
  top: -5%;
  left: 93%;

  display: block;

  width: 24px;
  height: 24px;

  border-radius: var(--radius-full);

  @media (width >= 1024px) {
    display: none;
  }
}

.aspectRatioWrapper {
  position: relative;
  transition: var(--transitionStyleButton);
}

.brandName {
  margin: 0;
  padding: 0;

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-000);
  font-weight: 700;
  line-height: 1;
  color: var(--n-color-text-subtle);
  text-transform: uppercase;
}

.rootForImage {
  overflow: hidden;

  width: 100%;
  height: 100%;

  object-fit: contain;
  background-color: var(--n-color-listing-background);
  border-radius: var(--radius-md);
}

.info {
  display: flex;
  flex-direction: column;
  gap: var(--n-size-2);
  align-items: flex-start;
  align-self: stretch;

  padding: var(--n-size-3) 0;
}

.price {
  display: flex;
  gap: var(--n-size-1);
  align-items: center;
}

.price__value {
  font-family: Montserrat;
  font-size: var(--n-font-size-0);
  font-weight: 600;
  color: var(--n-color-text-dark);
}

.price__originalValue {
  font-family: Montserrat;
  font-size: var(--n-font-size-00);
  font-weight: 400;
  color: var(--n-color-text-subtle);
  text-decoration: line-through;
}

.title {
  font-family: Montserrat;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--text-default-hover, var(--n-color-black));
}

.longWord {
  hyphens: auto;

  /* fallback option */
  word-break: break-all;

  /* use break-word if available */
  word-break: break-word;
}

.overlayInfo {
  position: absolute;
  inset: 0;

  display: grid;
  place-content: center;

  padding: var(--n-size-3);

  font-family: Syne, sans-serif;
  font-size: var(--n-font-size-1);
  font-weight: 700;
  color: var(--n-color-black);
  text-align: center;
  text-transform: uppercase;

  background: rgb(255 255 255 / 50%);
  border-radius: var(--radius-md);
}

.overlayInfo__container {
  display: grid;
  place-content: center;
  padding: var(--n-size-0) var(--n-size-1);
  border-radius: var(--radius-xs);
}

.overlayInfoPanel {
  backdrop-filter: blur(8px);
}

.manageMenuTrigger {
  cursor: pointer;

  position: absolute;
  top: var(--n-size-0);
  right: var(--n-size-0);

  display: grid;
  place-items: center;

  width: var(--n-size-6);
  height: var(--n-size-6);

  border: none;

  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
}

.transparentButton {
  align-items: center;
  justify-content: center;

  margin-top: var(--n-size-2);
  padding: var(--n-size-1) var(--n-size-8);

  color: var(--n-color-black);

  background: transparent;

  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:hover {
    color: var(--n-color-white);
    background: var(--n-color-black);
  }
}

.buttonInfoText {
  margin-top: var(--n-size-2);
  font-size: var(--n-font-size-00);
  color: var(--text-default-subtle);
  text-align: center;
}

.transparentButton + .buttonInfoText {
  display: block;
}
