.root {
  position: absolute;
  z-index: var(--zIndexPopup);

  margin-right: 10px;

  background: var(--n-color-white);
  border: 1px solid var(--n-color-text-disabled);
  border-radius: var(--radius-md);

  transition: var(--transitionStyleButton);
}

.isClosed {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}

.isOpen {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}

.content > li > a {
  /* Body/Body 1/Regural */
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
}
