.root {
  position: relative;
  width: 100%;
}

.listings {
  scrollbar-width: none; /* Firefox */

  overflow-x: auto;
  display: flex;
  gap: var(--n-size-3);

  max-width: 100%;
  padding: 0 var(--n-size-3);

  -ms-overflow-style: none; /* IE and Edge */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  & > *:first-child {
    margin-left: auto;
  }

  & > *:last-child {
    margin-right: auto;
  }

  & > * {
    width: 15vw;
    min-width: max(250px, 16vw);
  }
}
