@import url('../../styles/customMediaQueries.css');

.root {
  position: relative;
  width: 100%;

  /* Add loading color for the div */
  background-color: var(--marketplaceColor);
}

.overlay,
.backgroundImageWrapper {
  position: absolute;
  inset: 0;
}

.overlay {
  background: linear-gradient(-45deg, rgb(0 0 0 / 30%), rgb(0 0 0 / 60%));
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
