.root {
  display: flex;
  gap: var(--n-size-2);
  align-items: center;
  justify-content: start;
}

.arrowIcon {
  /* Color for svg icons */
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);

  &:hover {
    fill: var(--marketplaceColorDark);
    stroke: var(--marketplaceColorDark);
  }
}

.disabled,
.disabled:hover {
  fill: var(--colorGrey300);
  stroke: var(--colorGrey300);
}

.pageNumberList {
  display: flex;
  gap: var(--n-size-2);
  justify-content: space-between;
}

/**
 * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
 * However, since the length of pageNumberList can vary up to 7,
 * we need to keep track of how much space is allocated for the list
 * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
 */
.pageNumberList1Items {
  flex-basis: 30px;
}

.pageNumberList2Items {
  flex-basis: 60px;
}

.pageNumberList3Items {
  flex-basis: 100px;
}

.pageNumberList4Items {
  flex-basis: 160px;
}

.pageNumberList5Items {
  flex-basis: 200px;
}

.pageNumberList6Items {
  flex-basis: 250px;
}

.pageNumberList7Items {
  flex-basis: 275px;
}

.toPageLink {
  display: grid;
  place-items: center;

  min-width: 40px;
  height: 40px;

  /* Dimensions */
  padding: 0 12px;

  /* Body/Body 1/Regural */
  font-family: Montserrat;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  text-align: center;

  border: 1px solid black;

  &.prev,
  &.next {
    padding: 0;
  }
}

.currentPage {
  padding: var(--n-size-1) 12px;
  font-weight: 600;
  color: white;
  background-color: black;
}

.paginationGap {
  /* Dimensions */
  padding: var(--n-size-1) 12px;
}
