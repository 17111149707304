.root {
  position: relative;

  /* Layout */
  display: block;
  width: 100%;
}

/* Firefox doesn't support image aspect ratio inside flexbox */

/* Aspect ratio for is given inline */
.aspectPadding {
}

.aspectBox {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  inset: 0;
  width: 100%;
}
